const LANG_ITEM = document.querySelectorAll('.lang-item')
const MODAL_BG = document.querySelector('.modal-bg')
const MODAL_THANKS = document.querySelector('.modal-thanks')
const MODAL_ERROR = document.querySelector('.modal-error')
const closeModalBtn = document.querySelectorAll('.closeModal')
const openModal = document.querySelectorAll('.openModal')
const modalItems = document.querySelectorAll('.modal')
let width = window.innerWidth

let lang = localStorage.getItem('lang');
let url =  location.href
let urlPath = decodeURI(location.hash)
let mainText = document.querySelector('.title')
let descContent = document.querySelectorAll('.text-content')

const LIST_DESKTOP = document.querySelector('.list-desktop')
const LIST_MOBILE = document.querySelector('.list-mobile')
const CATALOG_ITEM = document.querySelectorAll('.catalog-item')
const TITLE_ORDER = document.querySelector('.modal-title-order')
const TITLE_CALC = document.querySelector('.modal-title-calc')
const MODAL_SUBTITLE = document.querySelector('.modal-subtitle')
const MODAL_BTN = document.querySelectorAll('.modal-btn')
const FORM_TITLE = document.querySelector('#formTitle')

CATALOG_ITEM.forEach((item) => {
    item.addEventListener('click', () => {
        let catalogList = item.querySelectorAll('.description-item')
        catalogList.forEach((descItem) => {
            if (width < 767) {
                LIST_DESKTOP.appendChild(descItem.cloneNode(true))
            }
            if (width > 767) {
                LIST_DESKTOP.appendChild(descItem.cloneNode(true))
            }
        })
        TITLE_ORDER.innerText = item.getAttribute('data-title')
        MODAL_SUBTITLE.innerText = item.getAttribute('data-gost')
        FORM_TITLE.value = item.getAttribute('data-title')
    })
})

MODAL_BTN.forEach((item) => {
    item.addEventListener('click' , () => {
        TITLE_CALC.innerText = item.getAttribute('data-title')
    })
})

descContent.forEach((text) => {
    let desc = text.innerText
    if (urlPath === text.getAttribute('data-desc')) {
        mainText.innerText = desc
    }
})

const LANG_TOGGLE = document.querySelector('.lang-toggle')
const LANG_LINK = document.querySelector('.lang-link')

LANG_TOGGLE.addEventListener('click', () => {
    LANG_LINK.click()
})

//Остановка прокрутки сайта
function scrollStop() {
    document.querySelector("html").style.overflow = 'hidden'
}

//Восстановление прокрутки сайта
function scrollAuto() {
    document.querySelector("html").style.overflow = ''
}

function closeModal() {
    MODAL_BG.classList.remove('active')
    modalItems.forEach((item) => {
        item.classList.remove('active')
    })
    LIST_DESKTOP.innerHTML = ''
    LIST_MOBILE.innerHTML = ''
    scrollAuto()
}

function openThanksModal() {
    closeModal()
    scrollStop()
    MODAL_BG.classList.add('active')
    MODAL_THANKS.classList.add('active')
}

function openErrorsModal() {
    closeModal()
    MODAL_BG.classList.add('active')
    MODAL_ERROR.classList.add('active')
}

closeModalBtn.forEach((item) => {
    item.addEventListener('click', () => {
        closeModal()
    })
})

openModal.forEach((item) => {
    item.addEventListener('click', () => {
        modalItems.forEach((modals) => {
            if (modals.classList.contains(item.getAttribute('data-modal'))) {
                MODAL_BG.classList.add('active')
                modals.classList.add('active')
                scrollStop()
            }
        })
    })
})


//Инициализация слайдеров
const logo = new Swiper('.slider-logo', {
    speed: 600,
    spaceBetween: 20,
    loop: true,
    slidesPerView: 'auto',
    autoplay: true,
});

const cases = new Swiper('.slider-wrapper', {
    speed: 400,
    spaceBetween: 20,
    loop: false,
    slidesPerView: "auto",
    autoplay: false,
    navigation: {
        nextEl: '.cases__button-next',
        prevEl: '.cases__button-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: "auto",
        },
        425: {
            slidesPerView: 2,
        },
        767: {
            slidesPerView: 2,
        },
        1199: {
            slidesPerView: "auto",
        },
    },
});

//Прикрепить файл
$(document).ready(function () {
    $("#main-upload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#mainFileName").html(filename);
    });
});

$(document).ready(function () {
    $("#delivery-upload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#deliveryFileName").html(filename);
    });
});

$(document).ready(function () {
    $("#cases-upload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#casesFileName").html(filename);
    });
});

$(document).ready(function () {
    $("#manufacture-upload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#manufactureFileName").html(filename);
    });
});

$(document).ready(function () {
    $("#modal-upload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#modalFileName").html(filename);
    });
});

$(document).ready(function () {
    $("#calc-upload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#calcFileName").html(filename);
    });
});




//Формирование формы аналитики
$(document).ready(function () {
    let url_string = location.href;
    let url = new URL(url_string);
    let utm_source = url.searchParams.get('utm_source');
    let utm_medium = url.searchParams.get('utm_meduim');
    let utm_compaign = url.searchParams.get('utm_compaign');
    let utm_content = url.searchParams.get('utm_content');
    let utm_term = url.searchParams.get('utm_term');
    let yclid = url.searchParams.get('yclid');
    let gclid = url.searchParams.get('gclid');
    let pm_position = url.searchParams.get('pm_position');
    let keyword = url.searchParams.get('keyword');
    let clientid;
    let googlecid;

    $('.analytics input[name="utm_source"]').val(utm_source);
    $('.analytics input[name="utm_medium"]').val(utm_medium);
    $('.analytics input[name="utm_compaign"]').val(utm_compaign);
    $('.analytics input[name="utm_content"]').val(utm_content);
    $('.analytics input[name="utm_term"]').val(utm_term);
    $('.analytics input[name="yclid"]').val(yclid);
    $('.analytics input[name="gclid"]').val(gclid);
    $('.analytics input[name="pm_position"]').val(pm_position);
    $('.analytics input[name="keyword"]').val(keyword);

    if (document.cookie.search('(?:^|;)\\s*_ga=([^;]*)') !== -1) {
        googlecid = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
        $('.analytics input[name="googlecid"]').val(googlecid[0].slice(10));
    } else {
        googlecid = 'GA - отсуствует'
        $('.analytics input[name="googlecid"]').val(googlecid);
    }

    if (document.cookie.search('(?:^|;)\\s*_ym_uid=([^;]*)') !== -1) {
        clientid = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
        $('.analytics input[name="clientid"]').val(clientid[1]);
    } else {
        clientid = 'YM - отсуствует'
        $('.analytics input[name="clientid"]').val(clientid);
    }

});

const googleStat = document.getElementById('googleStat')
const yandexStat = document.getElementById('yandexStat')

function sendStats() {
    if (googleStat) {
        googleStat.click()
    }

    if (yandexStat) {
        yandexStat.click()
    }
}


//Передача лида и формирование формы аналитики
$(function () {
    $('form').submit(function (e) {
        e.preventDefault()
        $(this).find('input').each(function() {
            let $input = $(this);
            let emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            if ($input.attr('name') === 'name' && /\d/.test($input.val())) {
                $input.addClass('invalid');
            }
            else if ($input.attr('name') === 'email' && $input.val() && !emailRegex.test($input.val())) {
                $input.addClass('invalid');
            } else {
                $input.removeClass('invalid');
            }
        });
        let $form = $(this),
            formID,
            formData = new FormData(this);
        $("form#analytics").serializeArray().forEach(function (field) {
            formData.append(field.name, field.value)
        });
        formID = "#" + $form.attr('id') + " button";  // Формируем переменную и номер формы и добавляем селектор button
        $(formID).prop('disabled', true);
        $(formID).css({'opacity': 0.3});
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
        }).done(function () {
            let fileName = 'Прикрепить файл';
            $('form').trigger('reset');
            $("#modalFileName").html(fileName);
            $("#manufactureFileName").html(fileName);
            $("#casesFileName").html(fileName);
            $("#deliveryFileName").html(fileName);
            $("#mainFileName").html(fileName);
            $('.modal-thanks').fadeIn();
            $(formID).prop('disabled', false);
            $(formID).css({'opacity': 1});
            openThanksModal()
            sendStats()
        }).fail(function () {
            openErrorsModal()
            $(formID).prop('disabled', false);
            $(formID).css({'opacity': 1});
        })
    });
});


